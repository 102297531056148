import { useRef, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './addTeam.css';
import Card from 'react-bootstrap/Card';


const AddTeam= ()=> {
    return (
      <Card className='add-team-wrapper'>
    <Form className='add-team'>
    <h1>ADD TEAM</h1>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="text" placeholder="Enter team 1 name" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="text" placeholder="Enter Location" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control  type="file" placeholder="upload team 1 logo" />
      </Form.Group>

      <Button variant="danger" type="submit">
        ADD
      </Button>
    </Form>
    </Card>
  );
}

export default AddTeam;