import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from "react-router-dom";

import Routes from './router'
import Navbar from './components/navbar'
import Footer from './components/footer/Footer';
import { createContext, useState } from 'react';
export const GlobalContext = createContext({});

process.env.NODE_ENV == 'development' ? window.host = 'http://localhost:8080/api/v1' : window.host = '/api/v1'

function App() {
  const [globalState, setGlobalState] = useState({
    editPlayer: {}
  });

  return (
    <main className='App'>
      <GlobalContext.Provider value={{ globalState, setGlobalState }}>
        <Router>
          <Navbar />
          <Routes />
          {/* <Footer /> */}
        </Router>
        <ToastContainer />
      </GlobalContext.Provider>
    </main>
  );
}

export default App;
