import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './addMatch.css'; // Import your scoped CSS

const AddMatch = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [flagImages, setFlagImages] = useState({ team1: '', team2: '' });
  const [tags, setTags] = useState('');
  const [sessions, setSessions] = useState([
    { session: 6, isSelected: false },
    { session: 10, isSelected: false },
    { session: 15, isSelected: false },
    { session: 20, isSelected: false },
  ]);
  const [customSession, setCustomSession] = useState('');
  const [predictions, setPredictions] = useState({
    team1: { 6: ['', ''], 10: ['', ''], 15: ['', ''], 20: ['', ''] },
    team2: { 6: ['', ''], 10: ['', ''], 15: ['', ''], 20: ['', ''] },
  });
  const [team1, setTeam1] = useState('');
  const [team2, setTeam2] = useState('');
  const [isAddingCustomSession, setIsAddingCustomSession] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'content') {
      setContent(value);
    } else if (name === 'tags') {
      setTags(value);
    } else if (name === 'customSession') {
      setCustomSession(value);
    }
  };

  const handleFlagChange = (e, team) => {
    const { value } = e.target;
    setFlagImages((prev) => ({ ...prev, [team]: value }));
  };

  const handleSessionChange = (session) => {
    setSessions((prev) =>
      prev.map((s) =>
        s.session === session ? { ...s, isSelected: !s.isSelected } : s
      )
    );
  };

  const handleCustomSessionAdd = () => {
    const sessionValue = parseInt(customSession, 10);
    if (sessionValue && !sessions.some((s) => s.session === sessionValue)) {
      setSessions((prev) => [
        ...prev,
        { session: sessionValue, isSelected: true },
      ].sort((a, b) => a.session - b.session));
      setCustomSession('');
      setIsAddingCustomSession(false);
    }
  };

  const handlePredictionChange = (team, session, index, value) => {
    const numericValue = value === '' ? '' : parseFloat(value).toString();
    setPredictions((prev) => ({
      ...prev,
      [team]: {
        ...prev[team],
        [session]: [
          index === 0 ? numericValue : prev[team][session][0],
          index === 1 ? numericValue : prev[team][session][1],
        ],
      },
    }));
  };

  const handleTeamChange = (e, team) => {
    const { value } = e.target;
    if (team === 'team1') {
      setTeam1(value);
      setTitle(`${value} vs ${team2}`);
    } else {
      setTeam2(value);
      setTitle(`${team1} vs ${value}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      title,
      content,
      flagImages,
      tags,
      sessions: sessions.filter(s => s.isSelected).map(s => s.session),
      predictions,
    });
  };

  return (
    <div className="add-match-body">
      <Container className="glassmorphic-container d-flex justify-content-center align-items-center">
        <Row className="w-100">
          <Col>
            <h2 className="text-center mb-4">Add Match</h2>
            <Form onSubmit={handleSubmit}>
              {/* Match Title */}
              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Match Title : {title}</Form.Label>
              </Form.Group>

              {/* Content */}
              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Content</Form.Label>
                <Form.Control
                  as="textarea"
                  className="add-match-input"
                  rows={3}
                  name="content"
                  value={content}
                  onChange={handleInputChange}
                  placeholder="Enter match content"
                />
              </Form.Group>

              {/* Team Selection */}
              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Team 1</Form.Label>
                <Form.Control
                  as="select"
                  value={team1}
                  onChange={(e) => handleTeamChange(e, 'team1')}
                >
                  <option value="">Select Team 1</option>
                  {/* Example team list */}
                  {['Team A', 'Team B', 'Team C'].map((team) => (
                    <option key={team} value={team}>{team}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Team 2</Form.Label>
                <Form.Control
                  as="select"
                  value={team2}
                  onChange={(e) => handleTeamChange(e, 'team2')}
                >
                  <option value="">Select Team 2</option>
                  {/* Example team list */}
                  {['Team A', 'Team B', 'Team C'].map((team) => (
                    <option key={team} value={team}>{team}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              {/* Team Flag URLs */}
              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Team 1 Flag URL</Form.Label>
                <Form.Control
                  className="add-match-input"
                  type="text"
                  value={flagImages.team1}
                  onChange={(e) => handleFlagChange(e, 'team1')}
                  placeholder="Enter Team 1 Flag URL"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Team 2 Flag URL</Form.Label>
                <Form.Control
                  className="add-match-input"
                  type="text"
                  value={flagImages.team2}
                  onChange={(e) => handleFlagChange(e, 'team2')}
                  placeholder="Enter Team 2 Flag URL"
                />
              </Form.Group>

              {/* Tags */}
              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Tags</Form.Label>
                <Form.Control
                  className="add-match-input"
                  type="text"
                  name="tags"
                  value={tags}
                  onChange={handleInputChange}
                  placeholder="Comma-separated tags"
                />
              </Form.Group>

              {/* Sessions */}
              <Form.Group className="mb-3">
                <Form.Label className="add-match-label">Sessions (Overs)</Form.Label>
                <div>
                  {sessions.map(({ session, isSelected }) => (
                    <Form.Check
                      inline
                      key={session}
                      label={session}
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleSessionChange(session)}
                    />
                  ))}
                </div>
                {!isAddingCustomSession && (
                  <Button variant="secondary" onClick={() => setIsAddingCustomSession(true)} className="mt-2">
                    + Add More Sessions
                  </Button>
                )}
                {isAddingCustomSession && (
                  <div className="mt-2 d-flex gap-2">
                    <Form.Control
                      type="number"
                      name="customSession"
                      value={customSession}
                      onChange={handleInputChange}
                      placeholder="Enter custom session"
                    />
                    <Button variant="primary" onClick={handleCustomSessionAdd}>
                      Add
                    </Button>
                    <Button variant="secondary" onClick={() => setIsAddingCustomSession(false)}>
                      Cancel
                    </Button>
                  </div>
                )}
              </Form.Group>

              {/* Predictions */}
              <h4 className="add-match-label">Predictions</h4>
              {sessions.filter(s => s.isSelected).map(({ session }) => (
                <div key={session} className="mb-3">
                  <Form.Label className="add-match-label">{session} Overs</Form.Label>
                  <div className="d-flex gap-3">
                    <Form.Group>
                      <Form.Label className="add-match-label">Team 1:</Form.Label>
                      <Form.Control
                        className="add-match-input"
                        type="number"
                        value={predictions.team1[session]?.[0] || ''}
                        onChange={(e) =>
                          handlePredictionChange('team1', session, 0, e.target.value)
                        }
                        placeholder="Enter value"
                      />
                      <Form.Control
                        className="add-match-input"
                        type="number"
                        value={predictions.team1[session]?.[1] || ''}
                        onChange={(e) =>
                          handlePredictionChange('team1', session, 1, e.target.value)
                        }
                        placeholder="Enter value"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="add-match-label">Team 2:</Form.Label>
                      <Form.Control
                        className="add-match-input"
                        type="number"
                        value={predictions.team2[session]?.[0] || ''}
                        onChange={(e) =>
                          handlePredictionChange('team2', session, 0, e.target.value)
                        }
                        placeholder="Enter value"
                      />
                      <Form.Control
                        className="add-match-input"
                        type="number"
                        value={predictions.team2[session]?.[1] || ''}
                        onChange={(e) =>
                          handlePredictionChange('team2', session, 1, e.target.value)
                        }
                        placeholder="Enter value"
                      />
                    </Form.Group>
                  </div>
                </div>
              ))}

              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddMatch;
