import axios from 'axios'

const getProfile = async (otherUser=false)=>{
    try{
        let userId;
        if(!otherUser){
            userId = JSON.parse(localStorage.getItem('userDetails'))._id;
        }
        else{
            userId = otherUser
        }
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.get(`${window.host}/profile/${userId}`, { headers });
        return data;
    }
    catch(err){
        if(process.env.NODE_ENV==='dev'){
            console.error(err.response.data.message);
        }
        return  []
    }
}

export default {getProfile}