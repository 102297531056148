import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Button from 'react-bootstrap/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './blog.css'
import blogService from '../../service/blogService'
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import toast from '../../service/toaster'
import ReactHtmlParser from 'react-html-parser';


export default function () {
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem("userDetails"))
    const [items, setItems] = useState([]);
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => { setIsReadMore(!isReadMore) };
    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        try {
            let { data } = await blogService.getBlog();
            data = data.map(ele => {
                return { ...ele, isReadMore: true }
            });
            setItems(data);
            // toast.success("All Blogs Found");
        }
        catch (err) {
            toast.error("Blog can't be seen!!!");
            navigate('/login');
        }
    }

    const likeUnlikeBlog = async (blogId, islike) => {
        try {
            setItems(blogs => blogs.map(blog => {
                if (blog._id === blogId) {
                    let likes = (islike) ? blog.likes.filter((ele) => { return ele != userDetails._id }) : [...blog.likes, userDetails._id]
                    // blog.likes.filter((ele) => { return ele != userDetails._id })
                    console.log(likes);

                    return {
                        ...blog,
                        islike: !blog.islike,
                        likes: likes
                    };
                }

                return blog;
            }))
            if (islike) {
                await blogService.unlikeBlog(blogId);
                toast.success(`You unliked a Blog`);
            }
            else {
                await blogService.likeBlog(blogId);
                toast.success(`You liked a Blog`);
            }
        }
        catch (err) {
            toast.error("Blog can't be liked!!!");
        }
    }

    const viewBlog = async (blogId) => {
        try {
            toast.success("All Blogs Found " + blogId);
            navigate('/blogs/view/' + blogId);
        }
        catch (err) {
            toast.error("Blog can't be seen!!!");
            navigate('/login');
        }
    }

    return (
      <div className="blog-container">
            {/* <div style={{ display: 'flex' }}>
                <h1>Blog Post</h1>
                <Button variant="primary" onClick={() => {
                    navigate("/blogs/add")
                }}>Add Blog</Button>
            </div> */}
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', height: '92vh' }}>

                {
                    items.slice(0, 25).map(elt => (
                        <Card sx={{ maxWidth: 345, margin: "10px" }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500], position: "inherit" }} aria-label="recipe">
                                        {elt.author.split(" ").map(ele => ele[0]?.toUpperCase())}
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={elt.title}
                                subheader={elt.published_at}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                style={{ height: '50vh', width: '50vh', objectFit: 'cover' }}
                                // image={require("../assets/cricket-player.png")}
                                image={"data:image/jpeg;base64," + elt.images}
                                onClick={() => viewBlog(elt._id)}
                            />
                            <CardContent className='content-container' onClick={() => viewBlog(elt._id)}>
                                <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: (elt.content.length > 150) ? elt.content.slice(0, 181) : elt.content }}>
                                </Typography>
                                <span>{(elt?.tags.length) ? `\n #${elt.tags[0].split(",").join(" #")}` : ""}</span>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites" onClick={() => likeUnlikeBlog(elt._id, elt.islike)}>
                                    {elt.islike ? (
                                        <FavoriteIcon style={{ fill: 'red' }} /> // Fill the icon with red color when liked is true
                                    ) : (
                                        <FavoriteBorderIcon />
                                    )
                                    }
                                </IconButton>{elt?.likes?.length || 0} likes
                            </CardActions>
                        </Card>
                    ))
                }
            </div>
            <div className="hover-button">
                <Button variant="primary" className="futuristic-button" onClick={() => navigate("/blogs/add")}>
                    <span className="button-icon">+</span>
                    <span className="button-text">Add Blog</span>
                </Button>
            </div>
        </div >
    );
}