import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import toast from '../../service/toaster'
import playerService from '../../service/playerAndTeamService';
import Button from 'react-bootstrap/Button';

// function stringToImage(base64String){
//     Buffer.from(base64String,'base64')
// }

function TeamList() {
    const [players, setPlayers] = useState([])

    useEffect(() => {
        playerService.listTeams().then(resp => {
            toast.success(resp.message);
            console.log('kyuki',resp)
            setPlayers(resp.data)
        }).catch(err => {
            toast.error("player can't be fetched!");
            console.log(err)
        })

    }, [])


    return (
        <div className='team-list-wrapper'>
            <Table className='team-list' striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Logo</th>
                        <th>location</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((elt, i) => (
                        <tr>
                            <td>{i + 1}</td>
                            <td className='name-col'>
                                <p>
                                {elt.name}
                                </p>{'  '}
                                <Button size='sm' variant="primary">Edit</Button>{' '}
                                <Button size='sm' variant="danger">Delete</Button>{' '}

                            </td>
                            <td>
                                <img src={"data:image/jpeg;base64," + elt.logo} width={300}/>
                                </td>
                            <td>{elt.location}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default TeamList;