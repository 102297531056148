import { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import toast from '../../service/toaster'
import playerAndTeamService from '../../service/playerAndTeamService';
import Button from 'react-bootstrap/Button';
import { GlobalContext } from '../../App';
import { useNavigate } from "react-router-dom";

function PlayerList() {
  const [players, setPlayers] = useState([])
  const { _, setGlobalState } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    playerAndTeamService.listPlayers().then(resp => {
      // console.log('sssss', resp)
      toast.success(resp.message);
      setPlayers(resp.data)
    }).catch(err => {
      toast.error("player can't be fetched!");
      console.log(err)
    })

  }, [])


  const deletePlayer = (id) => {
    playerAndTeamService.deletePlayer({ id }).then(resp => {
      toast.success(resp.message);
      setPlayers(players.filter(elt => elt._id !== id))
    }).catch(err => {
      toast.error("player can't be deleted!");
      console.log(err)
    })
  }

  const editPlayer = (elt) => {
    setGlobalState({
      editPlayer: elt
    })
    navigate('/edit-player')
  }

  return (
    <div className='player-list-wrapper'>
      <Table className='player-list' striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Pic</th>
            <th>location</th>
            <th>dob</th>
          </tr>
        </thead>
        <tbody>
          {players.map((elt, i) => (
            <tr>
              <td>{i + 1}</td>
              <td className='name-col'>
                <p>
                  {elt.name}
                </p>{'  '}
                <Button onClick={() => editPlayer(elt)} size='sm' variant="primary">Edit</Button>{' '}
                <Button onClick={() => deletePlayer(elt._id)} size='sm' variant="danger">Delete</Button>{' '}

              </td>
              <td>
                {elt.pic ? <img width={300} src={"data:image/jpeg;base64," + elt.pic} /> : <p>-</p>}
              </td>
              <td>{elt.location}</td>
              <td>{elt.dob || 'XXXX-XX-XX'}</td>

            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default PlayerList;