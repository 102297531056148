import React from 'react';
import Slider from 'react-slick';
import cover from "../../assets/cover.jpg";
import './home.css';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    // Slick Carousel settings
    const settings = {
        autoplay: true,
        autoplaySpeed: 3000, // 3 seconds
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        responsive: [
            {
                breakpoint: 768, // Adjust this breakpoint for small screens
                settings: {
                    slidesToShow: 1 // Show only one slide at a time on small screens
                }
            }
        ]
    };

    const navigate = useNavigate();

    const handlePredictNowClick = () => {
        navigate('/prediction');
    };


    return (
        <div className="main-container">
            <section className="hero">
                <div className="prediction-left">
                    <h2>Predictions</h2>
                    <p className="percentage">28%</p>
                    <p className="subtext">Watch Predictions</p>
                    <button className="predict-now" onClick={handlePredictNowClick}>Predict Now</button>
                </div>

                <div className="prediction-center" style={{
                    backgroundImage: `url(${cover})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '40vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    padding: '20px',
                }}>
                    <h2>Predict Now</h2>
                    <div className="score-card">
                        <div className="score">
                            <p>Teams</p>
                            <p>279</p>
                        </div>
                        <div className="score">
                            <p>Live Scores</p>
                            <p>228</p>
                        </div>
                    </div>
                </div>

                <div className="prediction-right">
                    <h2>Live Stats</h2>
                    <p className="percentage">49%</p>
                    <p className="subtext">Winning Predictions</p>
                    <button className="predict-now" onClick={handlePredictNowClick}>Predict Now</button>
                </div>
            </section>

            <section className="carousel-container">
                <Slider {...settings}>
                    <div className="crousal">
                        <img src="https://plus.unsplash.com/premium_photo-1679690884144-1f43b8f3bf41?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Live Stats" className="slider-image" />
                        <div className="overlay-text">Live Stats</div>
                    </div>
                    <div className="crousal">
                        <img src="https://images.unsplash.com/photo-1674986778924-7a33c1531443?q=80&w=1897&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Top Predictors" className="slider-image" />
                        <div className="overlay-text">Top Predictors</div>
                    </div>
                    <div className="crousal">
                        <img src="https://images.unsplash.com/photo-1706940388961-89c3c442c509?q=80&w=1970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blogs" className="slider-image" />
                        <div className="overlay-text">Blogs</div>
                    </div>
                    <div className="crousal">
                        <img src="https://images.unsplash.com/photo-1552435053-01c010307582?q=80&w=1912&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Add Match" className="slider-image" />
                        <div className="overlay-text">Add Match</div>
                    </div>
                </Slider>
            </section>

            <section className="footer">
            <div className="footer-links">
                <div onClick={() => navigate('/')} className="footer-link">Teams</div>
                <div onClick={()=> navigate('/blogs')} className="footer-link">News</div>
                <div onClick={() => navigate('/stats')} className="footer-link">Stats</div>
            </div>
            <p>&copy; 2024 Cricket Prediction. All Rights Reserved.</p>
        </section>
        </div >
    );
}
