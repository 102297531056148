import axios from 'axios'

const getPrediction = async (match_id) => {
    try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.post(`${window.host}/predictions`, { match_id }, { headers });
        return data;
    }
    catch (err) {
        if (process.env.NODE_ENV === 'dev') {
            console.error(err.response.data.message);
        }
        return []
    }
}

const doPrediction = async (predictionBody) => {
    try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.post(`${window.host}/predict`, { ...predictionBody }, { headers });
        return data;
    }
    catch (err) {
        if (process.env.NODE_ENV === 'dev') {
            console.error(err.response.data.message);
        }
        return []
    }
}

export default { getPrediction, doPrediction }