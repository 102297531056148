import axios from 'axios'

const getBlog = async (creds) => {
    try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.get(`${window.host}/blog`, { headers });
        return data;
    }
    catch (err) {
        throw new Error(err.response.data.message);
    }

}

const viewBlog = async (id) => {
    try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.get(`${window.host}/blog/${id}`, { headers });
        return data;
    }
    catch (err) {
        throw new Error(err.response.data.message);
    }

}

const addBlog = async (blogData) => {
    try {
        const accessToken = localStorage.getItem('token');
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.post(`${window.host}/blog`, blogData, { headers })
        return data;

    }
    catch (err) {
        throw new Error(err.response.data.message);
    }

}

const resetPassword = async (password) => {
    try {
        let { data } = await axios.put(`${window.host}/password`, password)
        return data;

    }
    catch (err) {
        throw new Error(err.response.data.message);
    }

}

const likeBlog = async (id) => {
    try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.put(`${window.host}/blog/like/${id}`, {}, { headers });
        return data;
    }
    catch (err) {
        throw new Error(err.response.data.message);
    }

}

const unlikeBlog = async (id) => {
    try {
        const accessToken = localStorage.getItem('token');
        console.log({ accessToken });
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.put(`${window.host}/blog/unlike/${id}`, {}, { headers });
        return data;
    }
    catch (err) {
        throw new Error(err.response.data.message);
    }

}

export default { getBlog, addBlog, resetPassword, unlikeBlog, likeBlog, viewBlog }