import axios from 'axios'
const login = async (creds)=>{
    try{
        let {data} = await axios.post(`${window.host}/login`,creds);
        localStorage.setItem('accessToken', data.data.token);
        return data;
    }
    catch(err){
        console.log(err);
        throw new Error("Please try again later!!!");
    }

}

const register = async (userInfo)=>{
    try{
        let {data} = await axios.post(`${window.host}/register`,userInfo)
        return data;
          
    }
    catch(err){
        throw new Error(err.response.data.message);
    }

}

const getOTP = async (otpReq)=>{
    try{
        let {data} = await axios.put(`${window.host}/otp`,otpReq)
        return data;
          
    }
    catch(err){
        throw new Error(err.response.data.message);
    }

}

const confirmOTP = async (otpReq)=>{
    try{
        let {data} = await axios.post(`${window.host}/otp`,otpReq)
        return data;
          
    }
    catch(err){
        throw new Error(err.response.data.message);
    }

}

const resetPassword = async (password)=>{
    try{
        let {data} = await axios.put(`${window.host}/password`,password)
        return data;
          
    }
    catch(err){
        throw new Error(err.response.data.message);
    }

}

export default {login,register,getOTP,confirmOTP,resetPassword}