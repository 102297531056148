import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import './match.css';
import { useEffect, useState } from 'react';
import toast from '../../service/toaster';
import matchService from '../../service/matchService'


export default function Matches() {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);

    useEffect(() => {
        getItems()
    }, []);

    const getItems = async () => {
        try {
            let { data } = await matchService.getMatches();
            data = data.map(ele => {
                return { ...ele, isReadMore: true }
            });
            setItems(data);
            toast.success("All Matches Found");
        }
        catch (err) {
            toast.error("Matches can't be seen!!!");
            navigate('/login');
        }
    }

    const viewMatch = (matchId) => {
        try {
            toast.success("Match details found: " + matchId);
            navigate('/matches/view/' + matchId);
        } catch (err) {
            toast.error("Cannot view match details!");
            navigate('/login');
        }
    };

    const goToPrediction = (matchId) => {
        navigate(`/prediction/add/${matchId}`);
    };

    return (
        <div className="matches-container">
            <div style={{ display: 'flex' }}>
                <h1>Live Matches</h1>
            </div>
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', height: '50vh' }}>
                {
                    items.slice(0, 25).map(elt => (
                        <Card sx={{ maxWidth: 345, margin: "10px", position: 'relative' }} key={elt._id}>
                            <CardHeader
                                title={elt.title}
                                subheader={elt.published_at}
                            />
                            <div className="flag-container">
                                <img src={elt.flagImages.team1} alt="Team 1 Flag" className="flag-image" />
                                <span className="vs-text">VS</span>
                                <img src={elt.flagImages.team2} alt="Team 2 Flag" className="flag-image" />
                            </div>
                            <CardContent className='content-container' onClick={() => viewMatch(elt._id)}>
                                <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: (elt.content.length > 150) ? elt.content.slice(0, 181) : elt.content }}>
                                </Typography>
                                <span>{(elt?.tags.length) ? `\n #${elt.tags[0].split(",").join(" #")}` : ""}</span>
                            </CardContent>
                            <Button 
                                variant="contained" 
                                className="prediction-button"
                                onClick={() => goToPrediction(elt._id)}
                            >
                                Predict
                            </Button>
                            <Button 
                                variant="outlined" 
                                className="view-prediction-button"
                                onClick={() => viewMatch(elt._id)}
                            >
                                View Predictions
                            </Button>
                        </Card>
                    ))
                }
            </div>
            <div className="hover-button">
                <Button variant="primary" className="futuristic-button" onClick={() => navigate("/add-match")}>
                    <span className="button-icon">+</span>
                    <span className="button-text">Add Match</span>
                </Button>
            </div>
        </div>
    );
}
