import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import blogService from '../../service/blogService'
import toast from '../../service/toaster'
import { useParams } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';


export default function () {
    const { id } = useParams();
    console.log("inside view blog", id);
    const [blog, setBlog] = useState(null);
    const findHourDifference = (dbDate) => {
        const date = new Date(dbDate);
        const currentDate = new Date();

        // Calculate the time difference in milliseconds
        const timeDiff = currentDate - date;

        // Convert milliseconds to hours
        return Math.floor(timeDiff / (1000 * 60 * 60));
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await blogService.viewBlog(`${id}`);
                console.log(response);
                setBlog(response.data[0]);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData(id);
    }, [id]);

    if (!blog) {
        return <div>Loading...</div>;
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <img style={{ height: '50vh', width: '100%', objectFit: 'contain' }} src={"data:image/jpeg;base64," + blog.images}></img>
            <h2>{blog.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: blog.content }}></p>
            <p>Author: {blog.author}</p>
            <p>Date: {(findHourDifference(blog.createdAt)) > 24 ? `${parseInt(findHourDifference(blog.createdAt) / 24)} days ago` : `${findHourDifference(blog.createdAt)} hours ago`}</p>
        </div>
    );
};
