import { useRef, useState, useEffect } from 'react'
import userService from '../../service/userService'
import toast from '../../service/toaster'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const Register = () => {
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [userinfo, setUserinfo] = useState({
        email: "",
        password: ""
    });
    const userinfoHandler = (e) => {
        setUserinfo(creds => ({ ...creds, [e.target.name]: e.target.value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        else {
            if (userinfo.password === userinfo.confirmPassword) {
                userService.register(userinfo).then(resp => {
                    toast.success(resp.message);
                    navigate('/login');

                }).catch(err => {
                    toast.error(err.message);
                    console.log(err)
                })
            }
        }
        setFormSubmitted(true)

    }

    return (
        <div style={{ height: "99.5vh" }}>
            <div className="wrapper" style={{ width: "100%", backgroundImage: `url(https://images.unsplash.com/photo-1631194758628-71ec7c35137e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80)` }}>
                <Form noValidate onSubmit={handleSubmit} className="login">
                    <div className="body-str">
                        <h2 className="text-center"><span><img src={require('../../assets/cricket-player.png')} height={50} width={50}></img></span> Cricket Prediction</h2>
                        <hr></hr>
                        <h4 className="text-center">Registration</h4>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="16" controlId="validationCustomUsername">
                                <Form.Label>First name</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><span><img src={require("../../assets/icons8-badge-24.png")} height={15} width={10}></img></span></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        placeholder="First name"
                                        onChange={userinfoHandler}
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        isInvalid={!userinfo?.firstName && formSubmitted}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide first name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="16" controlId="validationCustomUsername">
                                <Form.Label>Last name</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><span><img src={require("../../assets/icons8-badge-24.png")} height={15} width={10}></img></span></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        placeholder="Last name"
                                        onChange={userinfoHandler}
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        isInvalid={!userinfo?.lastName && formSubmitted}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide last name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="16" controlId="validationCustomUsername">
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><span className="fa fa-user"></span></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        onChange={userinfoHandler}
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        isInvalid={!userinfo.email && formSubmitted}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid email.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="16" controlId="validationCustom03">
                                <Form.Label>New password</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><span className="fa fa-lock"></span></InputGroup.Text>
                                    <Form.Control
                                        type={passwordType}
                                        name="password"
                                        onChange={userinfoHandler}
                                        placeholder="Password"
                                        required
                                        isInvalid={!userinfo.password && formSubmitted}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please provide password.
                                    </Form.Control.Feedback>

                                </InputGroup>

                            </Form.Group>

                        </Row>
                        {userinfo.password && <Row className="mb-3">
                            <Form.Group md="16">
                                <Form.Label>Confirm Password</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><span className="fa fa-lock"></span></InputGroup.Text>
                                    <Form.Control type={passwordType} name="confirmPassword" onChange={userinfoHandler} placeholder="Confirm Password"
                                        isInvalid={userinfo.password != userinfo.confirmPassword}
                                        required />
                                    <Form.Control.Feedback type="invalid">
                                        Password didn't matched!.
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>

                        </Row>}
                        <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            label="Show Password" style={{ fontSize: "12px" }}
                            onClick={() => { passwordType == 'password' ? setPasswordType("text") : setPasswordType("password") }}
                        />
                        <div style={{ textAlign: "center" }}>
                            <Button style={{ borderRadius: "5px" }} type="submit">Register</Button>
                            <a className="text-right mt-1" onClick={() => {
                                navigate("/login")
                            }} style={{ display: "block", fontSize: "12px" }}>Already Registered?</a>
                        </div>

                    </div>
                </Form>


            </div>
        </div>
    )
}

export default Register;