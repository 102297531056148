import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate, useLocation } from "react-router-dom";
import './index.css';

const pages = [{ name: "Live Scores", navigate: '/match' }, { name: "News", navigate: '/blogs' },{ name: "Stats", navigate: '/player' },{ name: "Contact Us", navigate: '/player' }];
const settings = [{ name: 'Profile', navigate: '/profile' }, { name: 'Dashboard', navigate: '/' }, { name: 'Logout', navigate: 'logout' }];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (route) => {
    setAnchorElNav(null);
    if (typeof route == 'string') {
      navigate(route)
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSetting = (route) => {
    setAnchorElUser(null);
    if (route == 'logout') {
      localStorage.clear();
      navigate('/login');
    }
    else {
      navigate(route)
    }
  }
  let nvRoutes = ['/login', '/register']

  return (

    !nvRoutes.includes(location.pathname) &&
    < AppBar position="sticky" style={{
      backgroundColor: "black", background: "linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)"
    }
    }>
      <Container maxWidth="xl" >
        <Toolbar disableGutters style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'contents', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => { handleCloseNavMenu(page.navigate) }}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <div style={{ cursor: 'pointer' }} onClick={() => { navigate('/') }}>
            {/* <h2 className="text-center" href="/"><span><img href="/" src={require('../../assets/cricket-player.png')} height={40} width={40}></img></span><span sx={{ flexGrow: 1, display: { xs: 'none', md: 'none' } }}>Cricket Predict</span></h2> */}
            <div className="cricket-prediction">
              <h2 className="text-center">
                <span className="cricket-player-icon"></span>
                <span className="cricket-prediction-text">Cricket Prediction</span>
              </h2>
            </div>
          </div>

          <Box style={{ paddingLeft: '10px' }} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => { handleCloseNavMenu(page.navigate) }}
                sx={{ my: 2, color: (location.pathname == page.navigate) ? 'grey' : 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Aemy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={() => { handleSetting(setting.navigate) }}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar >

  );
}
export default NavBar;
