import axios from 'axios'

const addPlayer = async (creds)=>{
    try{
        let {data} = await axios.post(`${window.host}/player`,creds)
        return data;
    }
    catch(err){
        throw new Error(err.response.data.message);
    }
}

const deletePlayer = async (creds)=>{
    try{
        let {data} = await axios.put(`${window.host}/player`,creds)
        return data;
    }
    catch(err){
        throw new Error(err.response.data.message);
    }
}

const updatePlayer = async (creds)=>{
    try{
        let {data} = await axios.put(`${window.host}/player`,creds)
        return data;
    }
    catch(err){
        throw new Error(err.response.data.message);
    }
}

const listPlayers = async ()=>{
    try{
        let {data} = await axios.get(`${window.host}/player`)
        return data;
    }
    catch(err){
        throw new Error(err.response.data.message);
    }
}

const listTeams = async ()=>{
    try{
        let {data} = await axios.get(`${window.host}/team`)
        return data;
    }
    catch(err){
        throw new Error(err.response.data.message);
    }
}

export default {addPlayer,updatePlayer,deletePlayer,listPlayers,listTeams}