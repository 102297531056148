import { useRef, useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './addPlayer.css';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import toast from '../../service/toaster'
import playerAndTeamService from '../../service/playerAndTeamService';
import { GlobalContext } from '../../App';

const EditPlayer = (props) => {
  const { globalState, _ } = useContext(GlobalContext);

  useEffect(()=>{
    console.log('sassa',globalState)
  },[])


  const [player, setPlayer] = useState({
    name: props.name || 'dilip',
    dob: props.dob || '',
    region: props.region || '',
    logo:props.logo || ''
  })

  const navigate = useNavigate();

  const handleChange = (e) => {
    setPlayer(old => ({
      ...old,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      playerAndTeamService.addPlayer(player).then(resp => {
        toast.success(resp.message);
        // navigate("/add-player")
      }).catch(err => {
        toast.error("player can't be added!");
        console.log(err)
      })
    }

  }


  return (
    <Card className='add-player-wrapper'>
      <Form className='add-player' method='POST'>
        <h1>ADD PLAYER</h1>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            required
            type="text"
            name="name"
            onChange={handleChange}
            value={player.name}
            placeholder="Enter player name"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            required
            type="file"
            name="logo"
            onChange={handleChange}
            value={player.logo}
            placeholder="upload player pic"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            type="date"
            name="dob"
            onChange={handleChange}
            value={player.dob}
            placeholder="Enter player age"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            type="text"
            name="region"
            onChange={handleChange}
            value={player.region}
            placeholder="Enter player region"
          />
        </Form.Group>
        <Button variant="danger" onClick={handleSubmit} type="submit">
          ADD
        </Button>
      </Form>
    </Card>
  );
}

export default EditPlayer;