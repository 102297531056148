
import blogService from '../../service/blogService'
import { useEffect, useRef, useState } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from "react-router-dom";
import toast from '../../service/toaster'
import { TagsInput } from "react-tag-input-component";
import { RotatingTriangles } from 'react-loader-spinner';
import Button from 'react-bootstrap/Button';

export default function () {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [isBlogAdded, setBlogAdded] = useState(false);
  const [image, setImage] = useState([]);
  const [blog, setBlog] = useState({
    title: '',
    content: ''
  });
  const editorRef = useRef(null);
  const log = () => {
    setBlogAdded(true);
    const formData = new FormData();
    formData.append('tags', selected);
    formData.append('title', blog.title);
    formData.append('content', blog.content);
    formData.append('file', image);
    blogService.addBlog(formData).then(resp => {
      toast.success(resp.message);
      setTimeout(() => {
        setBlogAdded(false);
      }, 2000)
      navigate("/blogs")
    }).catch(err => {
      setTimeout(() => {
        setBlogAdded(false);
      }, 2000)

      toast.error("blog can't be added!");
      console.log(err)
    });
  };
  const handleChange = (e) => {
    console.log(e.target.name);
    setBlog(old => ({
      ...old,
      [e.target.name]: e.target.value
    }))
  }
  const setImages = (e) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
    console.log({ image });
  }

  const editorchangehandler = (e) => {
    console.log(e);
    setBlog(old => ({
      ...old,
      ['content']: editorRef.current.getContent()
    }))
  }

  return (
    <div className='p-5'>
      <h1>Add Blog Post</h1>
      <FloatingLabel
        controlId="floatingTextarea"
        label="Title"
        className="mb-3"
      >
        <Form.Control as="textarea" name="title" value={blog.title} onChange={handleChange} placeholder="Leave a comment here" />
      </FloatingLabel>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => {
          editorRef.current = editor
        }}
        name="content"
        initialValue=''
        onChange={editorchangehandler}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}

      />
      <br />
      <TagsInput
        value={selected}
        onChange={setSelected}
        name="fruits"
        placeHolder="enter tags"
      />
      <br />

      <input type="file" name="file" onChange={setImages} />
      <br />


      <div style={{ display: "flex", justifyContent: "center" }}>
        <RotatingTriangles
          visible={isBlogAdded}
          height="80"
          width="80"
          colors={['#51E5FF', '#7DE2D1', '#FF7E6B']}
          ariaLabel="rotating-triangels-loading"
          wrapperStyle={{}}
          wrapperClass="rotating-triangels-wrapper"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="primary" onClick={log} disabled={isBlogAdded}>
          + Add Blog
        </Button>
      </div>
    </div>
  );
}